import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c(VContainer, {
    staticClass: "fill-height flex-column justify-center"
  }, [_c('ImagesSendwow', {
    staticClass: "mb-4",
    attrs: {
      "height": "62",
      "width": "242"
    }
  }), _vm._v(" "), _c(VCard, {
    staticClass: "py-8",
    attrs: {
      "elevation": "0",
      "width": "632"
    }
  }, [_c(VCardTitle, {
    staticClass: "justify-center"
  }, [_c(VProgressCircular, {
    attrs: {
      "size": "48",
      "color": "primary",
      "indeterminate": ""
    }
  })], 1), _vm._v(" "), _c(VCardTitle, {
    staticClass: "justify-center"
  }, [_c('h5', {
    staticClass: "mb-2"
  }, [_vm._v(_vm._s(_vm.$t('processing')))])])], 1)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };