import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
export default {
  mounted: function mounted() {
    var code = this.$route.query.code;
    if (code && window.opener) {
      window.opener.postMessage({
        smapo: true,
        message: 'google-auth-code',
        code: code
      }, window.location.origin);
      window.close();
    } else {
      this.$router.replace({
        path: '/login',
        query: {
          google_auth_code: code
        }
      });
    }
  }
};